import { HStack, Text, Tooltip } from "@chakra-ui/react";
import { NotificationStatus } from "iconsax-react";
import { useTranslation } from "react-i18next";

export default function NotificationMenuHeader({
  onMarkAllAsRead = async () => {},
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <HStack p={4} {...rest}>
      <HStack flexGrow={1} justify="center">
        <Text fontSize={18} fontWeight={500}>
          {t("Notifications")}
        </Text>
      </HStack>
      <Tooltip label={t("Mark all as read")} placement="top">
        <button onClick={onMarkAllAsRead}>
          <NotificationStatus />
        </button>
      </Tooltip>
    </HStack>
  );
}
