"use client";
import { normalSearchParams } from "@/app/(search)/_services/searchRecoil";
import { NORMAL_SEARCH } from "@/services/constants/routerPath";
import { Search2Icon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

export default function HeaderSearchSection() {
  const { t } = useTranslation();

  const { push } = useRouter();
  const pathName = usePathname();

  const setNormalSearchParams = useSetRecoilState(normalSearchParams);
  const searchParams = useRecoilValue(normalSearchParams);
  const [search, setSearch] = useState(searchParams.keyword);

  const onSearch = (e) => {
    e.preventDefault();
    setNormalSearchParams({
      ...searchParams,
      keyword: search,
      page: 1,
    });
    if (!pathName.includes(NORMAL_SEARCH)) {
      push(NORMAL_SEARCH);
    }
  };

  return (
    <HStack as="form" onSubmit={onSearch} gap={2}>
      <InputGroup w={360}>
        <Input
          placeholder={t("Search for applicant, job title")}
          value={search}
          border="1px solid #232F57"
          // value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <InputRightElement cursor="pointer" onClick={onSearch}>
          <Divider orientation="vertical" h={6} borderColor="primary.500" />
          <Search2Icon color="primary" m={3} />
        </InputRightElement>
      </InputGroup>
      <Button
        type="submit"
        variant="outline"
        fontWeight="semibold"
        px={4}
        minW="fit-content"
      >
        {t("Advanced")}
      </Button>
    </HStack>
  );
}
