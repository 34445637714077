"use client";
import { IconLogOut } from "@/app/_components/icons";
import MobileNavItem from "@/app/_components/layout/NavBar/MobileNavItem";
import FlexTabIndicator from "@/app/_components/share/FlexTabIndicator";
import { logout } from "@/app/_services/auth.action";
import { SIGNIN_PAGE } from "@/services/constants/routerPath";
import useMultiLanguageConstant from "@/services/hooks/useMultiLanguageConstant";
import { useUserInfo } from "@/services/hooks/useUserInfo";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Stack,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";
import { useIsClient } from "usehooks-ts";

const NavItemHover = dynamic(
  () => import("@/app/_components/layout/NavBar/NavItemHover"),
  { ssr: false }
);

export default function NavBar({ isOpen, onToggle }) {
  const { i18n, t } = useTranslation();
  const pathname = usePathname();
  const userData = useUserInfo();
  const isClient = useIsClient();
  const { NavigationMenu } = useMultiLanguageConstant();

  const renderNavBar = NavigationMenu.filter((item) => {
    const isHiddenMenu = item.hideToRoles?.includes(userData?.type);
    const isNotCommonMenu =
      item.onlyShowToRoles && !item.onlyShowToRoles?.includes(userData?.type);

    if (isHiddenMenu || isNotCommonMenu || item.isUseForMobileOnly)
      return false;
    return true;
  });

  const selectedTab = renderNavBar.find((navItem) =>
    pathname.includes(navItem.url)
  );
  const selectedTabIndex = renderNavBar.indexOf(selectedTab);

  if (!isClient) return null;

  return (
    <>
      <Show above="lg">
        <Box as="nav" w="full" h={10} mt={26}>
          <Tabs
            position="relative"
            variant="unstyled"
            isFitted
            defaultIndex={selectedTabIndex}
            index={selectedTabIndex}
          >
            <TabList>
              {renderNavBar.map((item, index) => {
                const isDisabled = !(item.url || item.sub);
                return (
                  <Tab
                    as="div"
                    key={index}
                    py={2}
                    px={0}
                    h={10}
                    tabIndex="-1"
                    whiteSpace="nowrap"
                    color={index === selectedTabIndex ? "secondary" : "menu"}
                    fontSize={14}
                    fontWeight="bold"
                    isDisabled={isDisabled}
                  >
                    <NavItemHover {...item} disabled={isDisabled} />
                  </Tab>
                );
              })}
            </TabList>
            <FlexTabIndicator
              key={i18n.language}
              tabIndex="-1"
              height="2px"
              bg="tag"
              borderRadius="1px"
              bottom="-2px"
            />
          </Tabs>
        </Box>
      </Show>
      <Show below="lg">
        <Menu isOpen={isOpen}>
          <MenuButton
            as={IconButton}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon boxSize={6} />}
            variant="unstyled"
            onClick={onToggle}
          />
          <MenuList
            as={Stack}
            minH="calc(100vh - 56px)"
            minWidth="100vw"
            rounded={0}
            p={4}
            gap={2}
          >
            {(!userData
              ? [NavigationMenu[0]].concat(NavigationMenu[1])
              : [NavigationMenu[3]]
            )
              .concat(renderNavBar)
              .map((menuItem) => {
                return (
                  <MobileNavItem
                    key={menuItem.url}
                    menuItem={menuItem}
                    closeMenu={onToggle}
                  />
                );
              })}
            {userData && (
              <MobileNavItem
                menuItem={{
                  label: t("Sign Out"),
                  icon: <IconLogOut color="red" />,
                  url: SIGNIN_PAGE,
                }}
                closeMenu={onToggle}
                onClick={logout}
              />
            )}
          </MenuList>
        </Menu>
      </Show>
    </>
  );
}
