import { IconNotification } from "@/app/_components/icons";
import NotificationMenuHeader from "@/app/_components/layout/Header/notification/NotificationMenuHeader";
import NotificationMenuList from "@/app/_components/layout/Header/notification/NotificationMenuList";
import {
  getUserNotifications,
  postReadNotification,
} from "@/services/api/notification.api";
import useIntersectionObserver from "@/services/hooks/useIntersectionObserver";
import {
  Avatar,
  AvatarBadge,
  Button,
  Center,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { flatten, get, orderBy } from "lodash";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useLockedBody } from "usehooks-ts";
export default function NotificationMenu() {
  const isRenderDrawer = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      fallback: false,
    }
  );
  const { t } = useTranslation();
  const [, lockBodyScroll] = useLockedBody(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data,
    isLoading,
    isRefetching,
    fetchNextPage,
    hasNextPage,
    refetch: fetchData,
  } = useInfiniteQuery(
    "nofifications",
    ({ pageParam }) =>
      getUserNotifications({
        page: pageParam ?? 1,
        limit: 10,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const notifications = flatten(
    data?.pages.map((result) =>
      orderBy(get(result, "docs", []), ["updatedAt"], ["desc"])
    )
  );

  const hasUnread = notifications.some((item) => !item.read);

  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    lockBodyScroll(isOpen);
  }, [isOpen, lockBodyScroll]);

  const onMarkAllAsRead = async () => {
    const allUnreadIds = notifications
      .filter((item) => !item.read)
      .map((item) => item._id);
    await postReadNotification({ ids: allUnreadIds });
    await fetchData();
  };
  return isRenderDrawer ? (
    <>
      <Avatar
        as={IconButton}
        isLoading={isLoading || isRefetching}
        variant="unstyled"
        color="primary.500"
        h={10}
        w={10}
        bgColor={{ base: "transparent", lg: "#EAE9E9" }}
        icon={<IconNotification color="#232F57" size={24} />}
        onClick={onOpen}
      >
        {hasUnread && (
          <AvatarBadge boxSize={4} bg="#6C8AC6" placement="top-end" />
        )}
      </Avatar>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack justify="space-between">
              <CloseButton onClick={onClose} />
              <NotificationMenuHeader
                onMarkAllAsRead={onMarkAllAsRead}
                flexGrow={1}
              />
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <NotificationMenuList
              data={notifications}
              onMarkAllAsRead={onMarkAllAsRead}
            />
          </DrawerBody>
          <DrawerFooter justify="center">
            <Center w="full">
              <Button
                variant="link"
                p={4}
                display={hasNextPage ? null : "none"}
                isLoading={isLoading}
                onClick={fetchNextPage}
              >
                {t("Load more")}
              </Button>
            </Center>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <Menu p={0} isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy>
      <MenuButton pos="relative">
        <Avatar
          as={IconButton}
          isLoading={isLoading || isRefetching}
          variant="unstyled"
          color="primary.500"
          h={10}
          w={10}
          bgColor="#EAE9E9"
          icon={<IconNotification color="#232F57" size={24} />}
        >
          {hasUnread && (
            <AvatarBadge boxSize={4} bg="#6C8AC6" placement="top-end" />
          )}
        </Avatar>
      </MenuButton>
      <MenuList
        maxW={375}
        w={375}
        minH={100}
        p={0}
        as={Stack}
        gap={0}
        fontSize="sm"
        rounded="xl"
        boxShadow="0px 6px 12px 0px rgba(0, 0, 0, 0.20)"
        border="none"
        color="menu"
        zIndex={4}
      >
        <NotificationMenuHeader
          onMarkAllAsRead={onMarkAllAsRead}
          fetchData={fetchData}
        />
        <Stack maxH="75vh" overflowY="auto">
          <NotificationMenuList
            data={notifications}
            fetchData={fetchData}
            px={4}
          />
          {hasNextPage && (
            <Center p={4}>
              <Button
                variant="link"
                isLoading={isLoading}
                ref={loadMoreButtonRef}
                onClick={fetchNextPage}
              >
                <Spinner size="sm" />
              </Button>
            </Center>
          )}
        </Stack>
      </MenuList>
    </Menu>
  );
}
