const {
  JOB_INFORMATION_DETAIL_PATH,
  PERSONAL_MY_PAGE,
  CORPORATE_MY_PAGE,
} = require("@/services/constants/routerPath");

export const NOTIFICATION_PATH = {
  JOB_POST: JOB_INFORMATION_DETAIL_PATH + "replacedId",
  PERSONAL_APPLY_RESUME_SCREENING: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_APPLY_REQUEST_REVIEW: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_APPLY_CONFIRM_INTERVIEW: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_APPLY_REMINDER_INTERVIEW: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_APPLY_RESULT_INTERVIEW: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_APPLY_CANCEL_INTERVIEW: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
  PERSONAL_TALENT_REQUEST_INTERVIEW: PERSONAL_MY_PAGE.LIST_OF_OFFER,
  PERSONAL_TALENT_CONFIRM_INTERVIEW: PERSONAL_MY_PAGE.LIST_OF_OFFER,
  PERSONAL_TALENT_RESULT_INTERVIEW: PERSONAL_MY_PAGE.LIST_OF_OFFER,
  PERSONAL_TALENT_CANCEL_INTERVIEW: PERSONAL_MY_PAGE.LIST_OF_OFFER,
  CORP_APPLY_PERSONAL_JOB:
    CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH + "replacedId",
  CORP_APPLY_ACCEPT_INTERVIEW:
    CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH + "replacedId",
  CORP_APPLY_CANCEL_INTERVIEW:
    CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH + "replacedId",
  CORP_APPLY_REQUEST_TIMECHANGE:
    CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH + "replacedId",
  CORP_APPLY_REMINDER_INTERVIEW:
    CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH + "replacedId",
  CORP_TALENT_ACCEPT_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
  CORP_TALENT_CANCEL_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
  CORP_TALENT_REQUEST_TIMECHANGE:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
  CORP_TALENT_REMINDER_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
  CORP_TALENT_INTEREST_ACCEPT_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST_FOR_COMPANY,
  CORP_TALENT_INTEREST_CANCEL_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST_FOR_COMPANY,
  CORP_TALENT_INTEREST_REQUEST_TIMECHANGE:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST_FOR_COMPANY,
  CORP_TALENT_INTEREST_REMINDER_INTERVIEW:
    CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST_FOR_COMPANY,
};
