import { SEARCH_TARGET } from "@/services/constants/global";
import { atom } from "recoil";

export const DEFAULT_SEARCH_JOB_LIMIT = 10;
export const DEFAULT_SEARCH_COMPANY_LIMIT = 15;

export const normalSearchParams = atom({
  key: "params",
  default: {
    keyword: "",
    career: [],
    businessType: [],
    subBusinessType: [],
    workType: [],
    monthSalaryMin: "",
    monthSalaryMax: "",
    limit: DEFAULT_SEARCH_JOB_LIMIT,
    page: 1,
  },
});

export const normalSearchTarget = atom({
  key: "target",
  default: SEARCH_TARGET.JOB,
});
