import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Collapse,
  HStack,
  IconButton,
  MenuItem,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function MobileNavItem({ menuItem, closeMenu, onClick }) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { push } = useRouter();

  const isDisabled = !(menuItem.url || menuItem.sub);

  useEffect(() => {
    onClose();
  }, []);

  return (
    <MenuItem as={Stack} gap={0} p={2} border="1px solid #E2E8F0" rounded="md">
      <HStack
        h={8}
        w="full"
        justify="space-between"
        onClick={onClick || onToggle}
        opacity={isDisabled ? 0.25 : 1}
      >
        {menuItem.icon}
        <Text
          onClick={
            onClick
              ? onClick
              : (e) => {
                  if (menuItem.url && !menuItem.sub) {
                    push(menuItem.url);
                    closeMenu();
                    e.stopPropagation();
                  }
                }
          }
          flexGrow={1}
        >
          {menuItem.label}
        </Text>
        {menuItem.sub?.length > 0 && (
          <IconButton
            variant="unstyled"
            icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          />
        )}
      </HStack>
      <Collapse in={isOpen} style={{ width: "100%" }}>
        <Stack pt={2}>
          {menuItem.sub?.map((sub) => {
            return (
              <Text
                key={sub.url}
                onClick={(e) => {
                  if (sub.url) {
                    push(sub.url);
                    closeMenu();
                    e.stopPropagation();
                  }
                }}
                fontSize={12}
                px={4}
                w="full"
              >
                {sub.label}
              </Text>
            );
          })}
        </Stack>
      </Collapse>
    </MenuItem>
  );
}
