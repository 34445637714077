import { postReadNotification } from "@/services/api/notification.api";
import { DATE_TIME_FORMAT_DEFAULT } from "@/services/constants/global";
import { NOTIFICATION_PATH } from "@/services/constants/notification";
import { formatDate } from "@/services/utils/formatter";
import { Link } from "@chakra-ui/next-js";
import { Box, HStack, Highlight, Stack, Text } from "@chakra-ui/react";
import { values } from "lodash";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";

export default function NotificationMenuList({ data = [], ...rest }) {
  const pathname = usePathname();
  const { t } = useTranslation();

  const onNotificationClick = (item) => {
    postReadNotification({ id: [item._id] });
  };

  return (
    <Stack gap={2} {...rest}>
      <Box>
        {data.map((item) => (
          <Box
            as={Link}
            key={item._id}
            href={String([NOTIFICATION_PATH[item.type]] ?? pathname).replace(
              "replacedId",
              item.applicant ?? item.job
            )}
            _hover={{ color: "secondary", textDecoration: "none" }}
            p={4}
            onClick={() => onNotificationClick(item)}
          >
            <Stack w="full" gap={2}>
              <HStack w="full" align="baseline" justify="space-between">
                <Text fontSize={12} noOfLines={3} role="group">
                  <Highlight
                    query={values(item.notification?.params)}
                    styles={{
                      fontWeight: "semibold",
                      _groupHover: { color: "secondary" },
                    }}
                  >
                    {t(item.notification?.text, item.notification?.params)}
                  </Highlight>
                </Text>
                <Box
                  flexShrink={0}
                  bg={item.read ? "transparent" : "#6C8AC6"}
                  h="10px"
                  w="10px"
                  borderRadius="50%"
                ></Box>
              </HStack>
              <Text fontSize={12} maxW={375} noOfLines={1}>
                {formatDate(item.updatedAt, DATE_TIME_FORMAT_DEFAULT)}
              </Text>
            </Stack>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}
