import ApiInstance from "@/services/api/api.utils";

export const getUserNotifications = async (params) => {
  const res = await ApiInstance.get("/notifications", {
    params,
  });
  return res?.notifications;
};

export const postReadNotification = async (payload) => {
  const res = await ApiInstance.post("/notifications/read", payload);
  return res;
};

export const postReadAllNotification = async (payload) => {
  const res = await ApiInstance.post("/notifications/read", payload);
  return res;
};

export const postSubscribePushNotification = async (payload) => {
  const res = await ApiInstance.post("/notifications/subscribe", payload);
  return res;
};
